import { graphql } from 'gatsby';
import { PageLayout, Title, Icon, RepoCard, Line } from '@josefaidt/gatsby-theme';
import SEO from '@josefaidt/gatsby-theme/src/components/seo';
import * as React from 'react';
export default {
  graphql,
  PageLayout,
  Title,
  Icon,
  RepoCard,
  Line,
  SEO,
  React
};