module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.2.2","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{"default":"/opt/build/repo/theme/src/layouts/page.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1300,"linkImagesToOriginal":false,"withWebp":true,"showCaptions":false,"backgroundColor":"transparent","wrapperStyle":"\n                  border-radius: 1rem;\n                  overflow: hidden;\n                ","tracedSVG":{"color":"#c6797ecc","borderRadius":"1rem","turnPolicy":"TURNPOLICY_MAJORITY"}}},"gatsby-remark-smartypants","gatsby-remark-copy-linked-files"],"remarkPlugins":[null]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"josefaidt.dev","icon":"content/assets/images/favicon.png","short_name":"josefaidt.dev","start_url":"/","background_color":"white","theme_color":"#c6797e","display":"minimal-ui"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"cacheId":"gatsby-plugin-offline","dontCacheBustUrlsMatching":{},"runtimeCaching":[{"urlPattern":{},"handler":"cacheFirst"},{"urlPattern":{},"handler":"staleWhileRevalidate"},{"urlPattern":{},"handler":"staleWhileRevalidate"},{"urlPattern":{},"handler":"staleWhileRevalidate"}],"skipWaiting":true,"clientsClaim":true},
    },{
      plugin: require('../../theme/gatsby-browser.js'),
      options: {"plugins":[],"offline":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://a65251eaf2db4970b6c3c5927d543c3f@sentry.io/1776261","environment":"production","enabled":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-134211753-2","head":false,"anonymize":true,"respectDNT":true},
    }]
