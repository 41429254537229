// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---content-pages-about-mdx": () => import("./../content/pages/about.mdx" /* webpackChunkName: "component---content-pages-about-mdx" */),
  "component---content-pages-index-mdx": () => import("./../content/pages/index.mdx" /* webpackChunkName: "component---content-pages-index-mdx" */),
  "component---content-pages-uses-mdx": () => import("./../content/pages/uses.mdx" /* webpackChunkName: "component---content-pages-uses-mdx" */),
  "component---content-pages-work-mdx": () => import("./../content/pages/work.mdx" /* webpackChunkName: "component---content-pages-work-mdx" */),
  "component---node-modules-gatsby-theme-blog-core-src-templates-post-query-js": () => import("./../../node_modules/gatsby-theme-blog-core/src/templates/post-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-core-src-templates-post-query-js" */),
  "component---theme-src-gatsby-theme-blog-core-templates-posts-query-js": () => import("./../../theme/src/gatsby-theme-blog-core/templates/posts-query.js" /* webpackChunkName: "component---theme-src-gatsby-theme-blog-core-templates-posts-query-js" */)
}

